'use client'

import { Heart } from '@phosphor-icons/react'
import { signIn, useSession } from 'next-auth/react'
import ToastMessage from 'react-hot-toast'

const FavouriteButton = ({
  personaId,
  isFavourite,
  setIsFavourite,
}: {
  personaId: string
  isFavourite: boolean | undefined
  setIsFavourite: (isFavourite: boolean) => void
}) => {
  const { status } = useSession()

  const saveFavourite = async () => {
    try {
      setIsFavourite(true)

      await fetch(`/api/user/favourite/${personaId}`, {
        method: 'POST',
      })
    } catch (error: unknown) {
      let e: string
      if (error instanceof Error) e = error.message
      else e = String(error)

      ToastMessage.error(e)
      setIsFavourite(false)
    }
  }

  const removeFavourite = async () => {
    try {
      setIsFavourite(false)

      await fetch(`/api/user/favourite/${personaId}`, {
        method: 'DELETE',
      })
    } catch (error: unknown) {
      let e: string
      if (error instanceof Error) e = error.message
      else e = String(error)

      ToastMessage.error(e)
      setIsFavourite(true)
    }
  }

  const onClickFavourite = () => {
    if (status !== 'authenticated') return signIn()

    if (isFavourite) removeFavourite()
    else saveFavourite()
  }

  return (
    <button
      className="ml-1.5 flex h-[30px] cursor-pointer flex-row items-center justify-center"
      onClick={onClickFavourite}
    >
      <Heart size={16} fill={isFavourite ? 'red' : undefined} weight={isFavourite ? 'fill' : 'regular'} />
    </button>
  )
}

export default FavouriteButton
