import { Alert } from '@/components/Alert'
import type { ServerActionError } from '@/server-actions/ServerActionsError'

interface InlineChunkReferenceProps {
  chunk?: string
  source?: string
  score?: number
  isOwner: boolean
  error?: ServerActionError
}

const InlineChunkReference = ({ chunk, source, score, error, isOwner: _isOwner }: InlineChunkReferenceProps) =>
  error ? (
    <Alert heading="Uh oh, something went wrong!">
      <p>An error occurred, please try again later.</p>
      <pre>{error.sentryId}</pre>
      <p>Send us your feedback if this problem continues to occur.</p>
      {process.env.NODE_ENV === 'development' && (
        <div className="bg-neutral-200 border-l-3 border-neutral-600 p-4 mt-4 overflow-x-auto rounded-md">
          <p className="font-bold">Development ONLY debug information</p>
          <p>{error.message}</p>
          <pre className="overflow-x-auto">Stack: {error.stack}</pre>
        </div>
      )}
    </Alert>
  ) : (
    <div
      className={`${error ? 'bg-red-100' : 'bg-gray-100'} p-4 rounded-md shadow-md whitespace-pre-wrap max-h-48 overflow-y-auto`}
    >
      <div className="-mt-4 flex justify-start">
        {score && <span className="mt-3 w-1/5 text-gray-500 text-sm">{score * 100}% confidence</span>}
        {source && <span className="mt-3 w-4/5 text-gray-500 text-sm">{source}</span>}
      </div>
      <div>{chunk}</div>
    </div>
  )

export default InlineChunkReference
