'use client'

import { CircleNotch } from '@phosphor-icons/react'
import Image from 'next/image'
import remarkGfm from 'remark-gfm'
import { twMerge } from 'tailwind-merge'

import { CodeBlock } from '@/components/CodeBlock'
import { MemoizedReactMarkdown } from '@/components/Markdown'
import { ChatDate } from './ChatDate'
import FavouriteButton from './FavouriteButton'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import type { ServerActionError } from '@/server-actions/ServerActionsError'
import { evaluateScore, getCarbonFileChunk } from '@/server-actions/carbonAI'
import { useMemo, useState } from 'react'
import { memo } from 'react'
import ReactDOM from 'react-dom'
import type { HistoryMessage } from './Chat'
import ChatImages from './ChatImages'
import InlineChunkReference from './InlineChunkReference'
import MessageButtons from './MessageButtons'

type ChatMessageProps = Pick<
  HistoryMessage,
  'id' | 'role' | 'content' | 'context' | 'elevenlabsId' | 'is_liked' | 'like_id' | 'memory' | 'images'
> & {
  previousContent?: string
  className?: string
  name: string
  createdAt: string
  profileUrl: string
  isPersonaTyping?: boolean
  personaId: string
  isFavourite?: boolean | undefined
  setIsFavourite: (favourited: boolean) => void
  model: string
  isOwner: boolean
  embed?: boolean
}

const remarkPlugins = [remarkGfm]

export default memo(function ChatMessage({
  className,
  role,
  name,
  profileUrl,
  content,
  previousContent,
  context: _context,
  createdAt,
  elevenlabsId = '',
  isPersonaTyping = false,
  personaId,
  isFavourite,
  setIsFavourite,
  is_liked,
  id,
  images,
  like_id,
  isOwner,
  embed = false,
}: ChatMessageProps) {
  const isUser = role === 'user'

  const contentWithReferences = useMemo(() => {
    let referenceIndex = 0
    return content
      .replace(/`/g, "'")
      .replace(/~/g, "'")
      .replace(
        /(\s*)\[(\d+)-(\d+)\]/g,
        (_match, space, fileId, chunkIndex) => `${space}[[${++referenceIndex}]](#load-chunk-${fileId}-${chunkIndex})`,
      )
  }, [content])

  const [chunkReferences, setChunkReferences] = useState<
    ({
      container?: HTMLDivElement | null
      chunk?: string
      fileId?: number
      chunkIndex?: number
      score?: number
      source?: string
      error?: ServerActionError
    } | null)[]
  >([])

  return (
    <div className={twMerge('flex flex-row gap-3', className)}>
      <div className="relative flex h-[30px] w-[30px] shrink-0 items-center justify-center overflow-clip rounded-full  border-white/50 shadow-md sm:h-[50px] sm:w-[50px]">
        {isUser && !profileUrl && (
          <div className="relative h-[50px] w-[50px] rounded-full">
            <Image alt="profile" src="/assets/user-avatar.png" fill className="object-contain" />
          </div>
        )}

        {profileUrl ? (
          <Image
            alt="profile"
            src={profileUrl}
            width={50}
            height={50}
            className="absolute inset-0 m-auto object-contain sm:object-cover"
          />
        ) : (
          !isUser && (
            <div className="flex h-[50px] w-[50px] items-center justify-center bg-[#eeeeee]">
              <CircleNotch size={16} color="#000000" className="animate-spin" />
            </div>
          )
        )}
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className="flex flex-row items-center justify-between gap-1">
          <div className="relative flex items-center">
            <span className="grow font-bold">{name}</span>

            {!isUser && !embed && (
              <div className="absolute -right-6">
                <FavouriteButton personaId={personaId} isFavourite={isFavourite} setIsFavourite={setIsFavourite} />
              </div>
            )}
          </div>

          <div className="flex items-end gap-x-2">
            {/* {context && (
              <PrimaryButton className="transform scale-75" onClick={() => setShowContext(!showContext)} type="button">
                {showContext ? 'Hide Context' : 'Show Context'}
              </PrimaryButton>
            )} */}
            <span className="shrink-0 text-xs opacity-50">{createdAt && <ChatDate createdAt={createdAt} />}</span>
          </div>
        </div>
        {/* {showContext && <div className="bg-gray-100 p-4 rounded-md shadow-md whitespace-pre-wrap">{context}</div>} */}
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={async (event) => {
            if (event.target instanceof HTMLAnchorElement && event.target.href.includes('#load-chunk-')) {
              const index = Number.parseInt(event.target.innerText.replace(/[\[\]]/g, '')) - 1
              event.preventDefault()
              const href = event.target.href
              const regex = /load-chunk-(\d+)-(\d+)/
              const match = href.match(regex)
              if (match) {
                const existingChunkContainer = event.target.nextElementSibling
                if (existingChunkContainer?.classList.contains('chunk-container')) {
                  existingChunkContainer.remove()
                  setChunkReferences((r) => {
                    r[index] = null
                    return [...r]
                  })
                  return
                }
                const chunkContainer = document.createElement('div')
                chunkContainer.className = 'chunk-container inline'
                event.target.after(chunkContainer)
                const fileId = match[1]
                const chunkIndex = match[2]
                try {
                  const { chunk, source } = await getCarbonFileChunk(
                    personaId,
                    Number.parseInt(fileId),
                    Number.parseInt(chunkIndex),
                  )
                  const score = await evaluateScore(
                    Number.parseInt(fileId),
                    Number.parseInt(chunkIndex),
                    `${previousContent}\n${content}`,
                    chunk,
                  )
                  setChunkReferences((r) => {
                    r[index] = {
                      container: chunkContainer,
                      fileId: Number.parseInt(fileId),
                      chunkIndex: Number.parseInt(chunkIndex),
                      chunk,
                      score,
                      source,
                    }
                    return [...r]
                  })
                } catch (error: unknown) {
                  setChunkReferences((r) => {
                    r[index] = {
                      container: chunkContainer,
                      fileId: Number.parseInt(fileId),
                      chunkIndex: Number.parseInt(chunkIndex),
                      error: error as ServerActionError,
                    }
                    return [...r]
                  })
                }
              }
            }
          }}
        >
          {chunkReferences.map(
            (reference) =>
              reference?.container &&
              (reference.chunk !== undefined || reference.error) &&
              ReactDOM.createPortal(
                <InlineChunkReference
                  isOwner={isOwner}
                  score={reference.score}
                  source={reference.source}
                  chunk={reference.chunk}
                  error={reference.error}
                />,
                reference.container,
              ),
          )}
          <MemoizedReactMarkdown
            className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
            remarkPlugins={remarkPlugins}
            components={{
              h3({ children }) {
                return <h3 className="pb-2 pt-4 text-2xl font-normal">{children}</h3>
              },
              p({ children }) {
                return <p className="mb-1 max-w-full whitespace-break-spaces last:mb-0">{children}</p>
              },
              ul({ children }) {
                return <ul className="my-0">{children}</ul>
              },
              ol({ children }) {
                return <ol className="my-0">{children}</ol>
              },
              a({ children, ...props }) {
                return (
                  <a
                    href={props.href}
                    target={'_blank'}
                    className="font-bold text-primary underline underline-offset-2 transition-opacity duration-300 hover:opacity-70"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                )
              },
              code({ /*node,*/ inline, className, children, ...props }) {
                if (children.length) {
                  if (children[0] === '▍') {
                    return <span className="mt-1 animate-pulse cursor-default">▍</span>
                  }

                  children[0] = (children[0] as string).replace('`▍`', '▍')
                }

                const match = /language-(\w+)/.exec(className || '')

                if (inline) {
                  return (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  )
                }

                return (
                  <CodeBlock
                    key={Math.random()}
                    language={match?.[1] || ''}
                    value={String(children).replace(/\n$/, '')}
                    {...props}
                  />
                )
              },
            }}
          >
            {contentWithReferences}
          </MemoizedReactMarkdown>
        </div>

        {role !== 'assistant' && <ChatImages images={images || []} />}

        {role === 'assistant' && !embed && (
          <MessageButtons
            isLiked={is_liked}
            messageId={id}
            name={name}
            // todo fix types
            like_id={like_id || ''}
            replicaId={personaId}
            content={content}
            // todo fix types
            elevenlabsId={elevenlabsId || ''}
            isPersonaTyping={isPersonaTyping}
            key={like_id}
          />
        )}
      </div>
    </div>
  )
})
