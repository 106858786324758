
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"461e6be3d271605b45c5121d586568ab09629afc":"likeMessage","5d94c06dc5848313ab4c92452b6436bd7b38a197":"$$ACTION_2","759eebfe149cf6ddac4704b0eef2f79078961165":"$$ACTION_1","939ce31a834ceaf9a34d94bf8a978500ad3c8095":"uploadImage","9e6a646b48c9eb80c67a89dfab676115d24499a6":"$$ACTION_0","f023c2beb468cf1b6200681070588e56426c6a39":"getCategories"} */ export var likeMessage = createServerReference("461e6be3d271605b45c5121d586568ab09629afc");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getCategories = createServerReference("f023c2beb468cf1b6200681070588e56426c6a39");
export var uploadImage = createServerReference("939ce31a834ceaf9a34d94bf8a978500ad3c8095");

