import type React from 'react'

interface TooltipProps {
  children: React.ReactNode
  tooltip: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, tooltip }) => {
  return <div title={tooltip}>{children}</div>
}

export default Tooltip
