'use client'

import type { Message } from 'ai'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { memo } from 'react'
import { type HistoryMessage, MAX_FREE_CHAT_MESSAGES, SIGNED_IN_MAX_FREE_CHAT_MESSAGES } from './Chat'
import ChatMessage from './ChatMessage'

export type MessageWithContext = Message & { context?: string | null }

export interface ChatHistoryProps {
  favourited: boolean
  messages: HistoryMessage[]
  isPersonaTyping: boolean
  anonymousTierExpired: boolean
  personaId: string
  freeTierExpired: boolean
  messageCap: boolean
  plan: string
  model: string
  isOwner: boolean
  embed?: boolean
}

function addItemToFilteredMessagesObject(acc: Record<string, HistoryMessage[]>, message: HistoryMessage) {
  const date = moment(message.createdAt).format('YYYY-MM-DD')
  if (!acc[date]) {
    acc[date] = []
  }
  acc[date] = [...acc[date], message]
  return acc
}

export default memo(function ChatHistory({
  favourited,
  messages: _messages,
  isPersonaTyping,
  anonymousTierExpired,
  personaId,
  freeTierExpired,
  messageCap,
  model,
  isOwner,
  embed = false,
}: ChatHistoryProps) {
  const maxChatMessagesToDisplay = freeTierExpired ? SIGNED_IN_MAX_FREE_CHAT_MESSAGES : MAX_FREE_CHAT_MESSAGES

  const [isFavourite, setIsFavourite] = useState<boolean | undefined>(favourited)

  const messages = [..._messages]
  const lastMessage = messages.at(-1)
  let typedMessage: HistoryMessage | null = null
  if (lastMessage && lastMessage.role === 'assistant' && isPersonaTyping) {
    typedMessage = messages.pop() || null
  }

  const _filteredMessages = useMemo(() => {
    return messages.reduce<Record<string, HistoryMessage[]>>(addItemToFilteredMessagesObject, {})
  }, [messages.length])

  const filteredMessages = { ..._filteredMessages }
  if (typedMessage) {
    // filteredMessages["test"] = "kokot"
    addItemToFilteredMessagesObject(filteredMessages, typedMessage)
  }

  return Object.keys(filteredMessages)?.map((date) => (
    <div className="flex flex-col relative" key={date}>
      <div className="self-center">
        <p className="text-xs text-[#331c12]/50">{date}</p>
      </div>

      <div className="flex flex-col gap-5 gap-y-8">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {filteredMessages[date].map((message, index) => {
          // If free tier expired, only show the first 6 messages
          if (anonymousTierExpired && index >= maxChatMessagesToDisplay) {
            return null
          }
          if (messageCap && freeTierExpired && index >= maxChatMessagesToDisplay) {
            return null
          }
          const shadeMessage =
            messageCap && (anonymousTierExpired || freeTierExpired) && index === maxChatMessagesToDisplay - 1

          const isLastMessageBeingTyped = isPersonaTyping && index === messages.length - 1

          return (
            <ChatMessage
              className={shadeMessage ? 'fade-b h-[6rem] overflow-y-clip' : undefined}
              key={message.id}
              {...message}
              previousContent={filteredMessages[date][index - 1]?.content}
              isPersonaTyping={isLastMessageBeingTyped}
              personaId={personaId}
              isFavourite={isFavourite}
              setIsFavourite={setIsFavourite}
              model={model}
              createdAt={String(message.createdAt)}
              isOwner={isOwner}
              embed={embed}
            />
          )
        })}
      </div>
    </div>
  ))
})
